import React, { useState, useEffect, useRef } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Row } from 'react-bootstrap';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import Loading from "../Common/Loading";
import { Line } from "react-chartjs-2";
import './CrashInspection.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import zoomPlugin from 'chartjs-plugin-zoom';
import { useParams } from "react-router";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend, Chart, registerables
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    zoomPlugin
);

/**
 * CrashInspection Component
 *
 * Displays detailed information about a specific crash, including accelerometer data visualization
 * and crash location on a map.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - Access token for authenticated API requests.
 *
 * @returns {JSX.Element} The rendered CrashInspection component.
 */
const CrashInspection = (props) => {
    const [CrashData, setCrashData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [crashId, setCrashId] = useState(null);
    const [smoothingEnabled, setSmoothingEnabled] = useState(true);
    const [vehicleReference, setVehicleReference] = useState(null);

    const navigate = useNavigate();

    const mapContainer = useRef(null);
    let map = useRef(null);

    const { vehiclereference, crashid, mapboxApi } = useParams();

    /**
     * Initializes the component:
     * - Sets the document title.
     * - Configures and initializes the Mapbox map.
     * - Fetches crash data based on URL parameters.
     */
    useEffect(() => {
        document.title = "AGMT | Crash";

        mapboxgl.accessToken = props.mapBoxApiKey;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            zoom: 9
        });

        if (vehiclereference && crashid) {
            setCrashId(crashid);
            setSmoothingEnabled(true)
            setVehicleReference(vehiclereference);
            getCrash(crashid, vehiclereference);
        } else {
            setError(true);
        }
    }, [vehiclereference, crashid]);

    /**
     * Fetches crash data whenever crashId or vehicleReference changes.
     */
    useEffect(() => {
        if (crashId && vehicleReference) {
            getCrash(crashId, vehicleReference);
        }
    }, [crashId, vehicleReference]);


    /**
     * Updates the Mapbox map based on the fetched crash data.
     */
    useEffect(() => {
        if (CrashData && CrashData.crashAccelerometerEventsX && map.current) {
            const { longitude, latitude } = CrashData;

            if (longitude >= -180 && longitude <= 180 && latitude >= -90 && latitude <= 90) {
                map.current.setCenter([longitude, latitude]);

                new mapboxgl.Marker()
                    .setLngLat([longitude, latitude])
                    .addTo(map.current);
            }
        }
    }, [CrashData]);

    /**
     * Fetches crash details from the API.
     *
     * @param {string} crashId - The ID of the crash.
     * @param {string} vehicleReference - The reference identifier for the vehicle.
     * @param {boolean} [smoothingEnabledValue] - Optional parameter to override the current smoothing state.
     */
    const getCrash = (crashId, vehicleReference, smoothingEnabledValue = smoothingEnabled) => {
        fetch(`/api/Crash/GetCrash?VehicleReference=${vehicleReference}&CrashId=${crashId}&SmoothingEnabled=${smoothingEnabledValue}`, GetHttpConfig(props.userAccessToken))
            .then((response) => response.json())
            .then((data) => {
                setCrashData(data);
                setLoading(false);
                setError(false);
            })
            .catch((error) => {
                console.error(error);
                setCrashData(null);
                setLoading(false);
                setError(true);
            });
    };

    /**
     * Prepares data for the accelerometer line chart.
     *
     * @returns {Object} Chart data and configuration.
     */
    const getChartData = () => {
        const { crashAccelerometerEventsX, crashAccelerometerEventsY, crashAccelerometerEventsZ } = CrashData;

        const dataLength = crashAccelerometerEventsX.length;

        const labels = Array.from({ length: dataLength }, (_, i) => (i / 100).toFixed(2));

        const dataX = crashAccelerometerEventsX.map(val => val / 1000);
        const dataY = crashAccelerometerEventsY.map(val => val / 1000);
        const dataZ = crashAccelerometerEventsZ.map(val => (val / 1000 - 1));

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Accelerometer data X',
                    data: dataX,
                    fill: false,
                    borderColor: 'rgb(255, 0, 0)',
                    backgroundColor: 'rgb(255, 0, 0)',
                    pointRadius: 0,
                    tension: 0.4,
                    borderWidth: 1
                },
                {
                    label: 'Accelerometer data Y',
                    data: dataY,
                    fill: false,
                    borderColor: 'rgb(255,145,0)',
                    backgroundColor: 'rgb(255,145,0)',
                    pointRadius: 0,
                    tension: 0.4,
                    borderWidth: 1
                },
                {
                    label: 'Accelerometer data Z',
                    data: dataZ,
                    fill: false,
                    borderColor: 'rgb(0, 0, 255)',
                    backgroundColor: 'rgb(0, 0, 255)',
                    pointRadius: 0,
                    tension: 0.4,
                    borderWidth: 1
                }
            ],
        };
    };

    /**
     * Configures options for the accelerometer line chart.
     *
     * @returns {Object} Chart options.
     */
    const getChartOptions = () => {
        return {
            maintainAspectRatio: false,
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                tooltip: {
                    enabled: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        label: function(context) {
                            let label = context.dataset.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += context.parsed.y.toFixed(3);
                            }
                            return label;
                        }
                    }
                },
                legend: {
                    display: true
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                    },
                    pan: {
                        enabled: true,
                        mode: 'x',
                    }
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Time (seconds)'
                    },
                    beginAtZero: true,
                    ticks: {
                        stepSize: 0.01
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Acceleration (g)'
                    }
                }
            }
        };
    };


    let content = null;
    if (loading) {
        content = <Loading />;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (!CrashData || !CrashData.crashAccelerometerEventsX || CrashData.crashAccelerometerEventsX.length === 0) {
        content = <p>No crash accelerometer data found.</p>;
    } else {
        const chartData = getChartData();
        const chartOptions = getChartOptions();
        content = <Line data={chartData} options={chartOptions} style={{ width: '100%', height: '70vh' }} />;
    }

    let confidence = (CrashData.confidence * 100).toFixed(0);
    let severity = parseFloat(CrashData.severity).toFixed(2);
    let maxAcceleration = (CrashData.maximumAcceleration / 1000).toFixed(2);

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Crash Inspection</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={3}>
                        <div className="d-flex justify-content-between align-items-center" style={{ marginTop: '10px' }}>
                            <BackButtonLogoHolder backlink="/crash/crash-list"  />
                            {CrashData.isCrash && (
                                <>
                                    <a target="_blank" rel="noopener noreferrer" href={CrashData.theCrashReportLink}>
                                        <Button variant="primary" style={{ marginLeft: '10px' }}>Download Crash Report</Button>
                                    </a>
                                </>
                            )}
                            <Button
                                variant={smoothingEnabled ? "success" : "secondary"}
                                onClick={() => {
                                    const newSmoothingEnabled = !smoothingEnabled;
                                    setSmoothingEnabled(newSmoothingEnabled);
                                    getCrash(crashId, vehicleReference, newSmoothingEnabled);
                                }}
                                style={{ marginLeft: '10px' }}
                            >
                                {smoothingEnabled ? "Disable Smoothing" : "Enable Smoothing"}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12} className="chart-col">
                        <div className="chart-wrapper-crash">
                            <div className="tile">
                                <h5>Crash ID</h5>
                                <div className="tile-content">
                                    {crashId}
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Crash Time</h5>
                                <div className="tile-content">
                                    <Moment format="DD/MM/YYYY HH:mm:ss">{CrashData.crashDateTime}</Moment>
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Confidence</h5>
                                <div className="tile-content">
                                    {confidence}%
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Severity</h5>
                                <div className="tile-content">
                                    {severity}
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Altitude</h5>
                                <div className="tile-content">{CrashData.altitude}</div>
                            </div>
                            <div className="tile">
                                <h5>Readings</h5>
                                <div className="tile-content">{CrashData.numberOfAccelerometerReadings}</div>
                            </div>
                            <div className="tile">
                                <h5>Max Acceleration (X/Y)</h5>
                                <div className="tile-content">{maxAcceleration}G</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6}>
                        <div className="crash-chart-wrapper">
                            {content}
                        </div>
                    </Col>
                    <Col md={6} lg={6}>
                        <div className="crash-map-wrapper">
                            <div ref={mapContainer} style={{ width: '100%', height: '70vh', borderRadius: '15px' }} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CrashInspection;
